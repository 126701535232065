.login-wrapper {
  display: -webkit-flex;
  display: flex;
}
.login-wrapper .Login {
  -webkit-flex: 1 0 100%;
          flex: 1 0 100%;
  text-align: center;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 108px 33px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  background-image: url(/static/media/symbol_background.7ca4c195.png);
  color: #690986;
  height: 100vh;
}
.login-wrapper .Login .content-wrap {
  width: 100%;
}
.login-wrapper .Login .content-wrap a {
  border-bottom: 1px solid #f5af00;
  color: #f5af00;
  font-weight: 500;
}
.login-wrapper .Login .content-wrap .logo {
  height: 70px;
  margin-bottom: 34px;
}
.login-wrapper .Login .content-wrap .form-wrapper {
  text-align: left;
}
.login-wrapper .Login .content-wrap .form-wrapper .title {
  font-size: 18px;
  font-weight: 500;
  font-family: inherit;
}
.login-wrapper .Login .content-wrap .form-wrapper .register-suggestion {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
}
.login-wrapper .Login .content-wrap .form-wrapper .form {
  margin-top: 10px;
}
.login-wrapper .Login .content-wrap .form-wrapper .form .input-wrapper .info {
  margin: 10px 0 0;
}
.login-wrapper .Login .content-wrap .form-wrapper .form .input-wrapper .info label {
  font-size: 12px;
  font-weight: 500;
}
.login-wrapper .Login .content-wrap .form-wrapper .form .input-wrapper input {
  width: 100%;
  height: 46px;
  border: 1px solid #690986;
  border-radius: 0;
  padding-left: 13px;
  font-weight: 300;
}
.login-wrapper .Login .content-wrap .form-wrapper .form button {
  width: 100%;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 6px;
  padding: 12px 0;
  margin-top: 14px;
}
.login-wrapper .Login .content-wrap .form-wrapper .form .bottom-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 30px;
  font-size: 12px;
}
.login-wrapper .Login .content-wrap .form-wrapper .form .bottom-content .checkbox-wrapper {
  margin: 0;
}
.login-wrapper .Login .content-wrap .form-wrapper .form .bottom-content .checkbox-wrapper label {
  font-size: 13px;
}
.login-wrapper .background {
  display: none;
  -webkit-flex: 1 0 50%;
          flex: 1 0 50%;
  height: 100vh;
  width: 50%;
  background-image: url(/static/media/login_right_bg.9ae77374.png);
  background-position: center;
  background-size: cover;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.login-wrapper .background .icon, .login-wrapper .background .logo {
  width: 35%;
  display: block;
}

@media (min-width: 723px) {
  .login-wrapper .Login {
    -webkit-flex: 1 0 50%;
            flex: 1 0 50%;
  }
  .login-wrapper .Login .content-wrap {
    width: 300px;
  }
  .login-wrapper .background {
    display: -webkit-flex;
    display: flex;
  }
}
.ui.modals .ui.modal .content {
  text-align: center;
  background: #690986;
  border-radius: 0;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
}
.ui.modals .ui.modal .content h3 {
  font-size: 30px;
  margin-top: -15px;
  margin-bottom: 15px;
}
.ui.modals .ui.modal .content p {
  font-size: 18px;
  line-height: 1.4;
}
.ui.modals .ui.modal .content img {
  width: 150px;
  margin-bottom: 20px;
}
.ui.modals .ui.modal .content .btn {
  margin: 30px auto 45px;
  min-width: 30%;
}
.buy-bg {
  background-image: url(/static/media/symbol_background.7ca4c195.png);
  padding: 50px 0;
  min-height: 100vh;
  background-repeat: repeat;
  background-size: 70%;
}

.SignUp {
  color: #690986;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #fff;
  text-align: center;
  padding-top: 50px;
  min-height: 89vh;
  border-top: 1px solid #690986;
  border-bottom: 1px solid #690986;
}
.SignUp .ui.container.grid .column.mobile.sixteen {
  padding: 0;
}
.SignUp h1 {
  font-weight: 900;
  font-size: 30px;
  font-family: inherit;
  margin: 0 34px 33px;
  color: #3B186E;
}
.SignUp .info-wrapper {
  padding: 0 34px;
  text-align: center;
}
.SignUp .info-wrapper .desc {
  text-align: left;
  padding: 0 15px;
  font-weight: 300;
  font-size: 12px;
}
.SignUp .info-wrapper .desc h2 {
  font-weight: 900;
  font-family: inherit;
  font-size: 18px;
  color: #3B186E;
}
.SignUp .info-wrapper .summary-wrapper {
  margin: 35px 0;
}
.SignUp .info-wrapper .logo {
  padding: 0 50px;
  margin-bottom: 40px;
}
.SignUp .info-wrapper .logo img {
  width: 100%;
  max-width: 180px;
}
.SignUp .form {
  background-color: rgba(208, 232, 218, 0.4);
  padding: 26px;
  text-align: left;
  margin: 0 -1em 30px;
}
.SignUp .form .PaymentForm {
  margin-top: 30px;
}
.SignUp .form button {
  margin-top: 25px;
  width: 100%;
}

.StripeElement {
  width: 100%;
  border: 1px solid #690986;
  border-radius: 2px;
  background-color: #FFFFFF;
  padding: 13.1px 10px;
}

@media (min-width: 723px) {
  .SignUp .ui.grid {
    margin: 0;
  }
  .SignUp .form-wrapper .row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .SignUp .form-wrapper .row .input-wrapper {
    width: 47%;
  }
}
.PaymentForm .payment-symbols {
  height: 30px;
  margin-top: 10px;
}
.PaymentForm .payment-symbols img {
  height: 100%;
}
.summary-wrapper {
  background-color: #ffd61e;
  border-radius: 2px;
  border: 1px solid #690986;
  text-align: left;
}
.summary-wrapper .title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 7px;
  color: #690986;
  padding: 20px 16px 0;
}
.summary-wrapper table {
  width: 100%;
  font-size: 12px;
  border-bottom: 0.5px solid #690986;
  padding: 0 16px 30px;
}
.summary-wrapper table th {
  font-weight: 700;
  color: #690986;
}
.summary-wrapper table td {
  font-weight: 300;
}
.summary-wrapper .total {
  display: -webkit-flex;
  display: flex;
  margin-top: 17px;
  font-size: 18px;
  font-weight: 700;
  color: #690986;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 16px 16px;
}
.PasswordEmail p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 300;
}
.PasswordEmail .form .actions-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.PasswordEmail .form .actions-wrapper .btn {
  padding: 13px 25px;
  margin-right: 15px;
}
.PasswordEmail .form .actions-wrapper a {
  color: #f5af00;
}
.ResetPasswordLayout {
  text-align: center;
  background-image: url(/static/media/symbol_background.7ca4c195.png);
}
@media (min-width: 723px) {
  .ResetPasswordLayout {
    width: 933px;
    margin-left: auto;
    margin-right: auto;
  }
}
.ResetPasswordLayout .logo {
  height: 42px;
  margin: 20px 0;
}
.ResetPasswordLayout .content {
  padding: 30px 30px 50px 30px;
  border-top: 1px solid #690986;
  border-bottom: 1px solid #690986;
  text-align: left;
  background-color: #fff;
}
.ResetPasswordLayout .content h1 {
  font-size: 22px;
  font-weight: 500;
  font-family: "Source Sans Pro", sans-serif;
}
.ResetPasswordLayout .content .form {
  font-size: 12px;
}
.ResetPasswordLayout .content .form .input-wrapper label {
  font-weight: 800;
  margin-bottom: 8px;
}
.ResetPasswordLayout .content .form .input-wrapper input {
  border: 1px solid #690986;
  border-radius: 2px;
  height: 45px;
  margin-bottom: 15px;
}
.PasswordReset .actions-wrapper {
  text-align: center;
}
.PasswordReset .actions-wrapper .btn {
  width: 100%;
  margin-bottom: 15px;
}
.PasswordReset .actions-wrapper a {
  color: #f5af00;
}
.Tile {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding: 10px;
}
.Tile .graduation-cap {
  position: absolute;
  left: 10px;
  top: 14px;
  z-index: 3;
  height: 18px;
  width: 18px;
}
.Tile .text {
  text-align: center;
}
.Tile .text h2, .Tile .text h3 {
  font-family: "Source Sans Pro", sans-serif;
}
.Tile .text h2 {
  font-weight: 500;
  font-size: 24px;
}
.Tile .text h3 {
  font-weight: 100;
  font-size: 20px;
}
.Courses {
  padding-top: 41px;
}
.Breadcrumbs {
  display: -webkit-flex;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 15px;
  color: #690986;
}
.Breadcrumbs svg {
  margin: 0 5px;
  color: #690986;
}
.Breadcrumbs span {
  font-weight: 500;
}
.Breadcrumbs span.bold {
  font-weight: 900;
}

.Upgrade {
  margin: 0 -30px;
  padding: 0 30px 30px;
  border-top: solid 1px #690986;
  border-bottom: solid 1px #690986;
}
.Upgrade .title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 1.4;
  margin: 30px 0 10px;
}
.Upgrade .sub-title {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 1.4;
  margin: 0 10% 20px;
}
.Upgrade .sub-title a {
  color: #f5af00;
}
.Upgrade .product-card {
  color: white;
  background-color: #690986;
  border-radius: 15px;
  overflow: hidden;
}
.Upgrade .product-card .product-card-header {
  background-color: #41094a;
  padding: 15px 0;
  text-align: center;
}
.Upgrade .product-card .product-card-header .title {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 0.5px;
  margin: 0;
}
.Upgrade .product-card .product-card-header .price {
  font-size: 30px;
  font-weight: 900;
  color: #ffd61e;
}
.Upgrade .product-card .product-card-footer {
  padding: 20px 20px;
}
.Upgrade .product-card .btn {
  margin: 15px 0 0;
  padding: 5px;
  font-size: 16px;
  display: inline-block;
}
.Upgrade.Form {
  background-color: white;
}
.Upgrade.Form .form {
  background-color: rgba(208, 232, 218, 0.4);
  padding: 15px;
  margin-top: 30px;
}
.Upgrade.Form .form .PaymentForm h3 {
  font-weight: 800;
}
.Upgrade.Form .form .PaymentForm .input-wrapper {
  margin-top: 15px;
}
.Upgrade.Form .form .PaymentForm .row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.Upgrade.Form .form .PaymentForm .row .input-wrapper {
  -webkit-flex: 0 0 48%;
          flex: 0 0 48%;
}
.Upgrade.Form .form button {
  width: 90%;
  margin: 20px auto;
}
.Upgrade.Congrats {
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 70vh;
  text-align: center;
  padding: 0 35px;
}
.Upgrade.Congrats i {
  color: #690986;
}
.Upgrade.Congrats h1 {
  color: #690986;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1.2px;
  font-family: "Source Sans Pro", sans-serif;
  margin-top: 15px;
}
.Upgrade.Congrats p {
  font-size: 18px;
}
.Upgrade.Congrats .btn {
  margin-top: 15px;
  width: 75%;
  font-weight: 900;
}
.group-wrapper {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.group-wrapper .slick-slider {
  transition: 0.5s ease;
}
.group-wrapper .slick-slider .slick-list {
  overflow: visible;
  width: 80%;
}
.group-wrapper .slick-slider .slick-list .slick-slide .tile-wrap {
  margin-right: 5px;
}
.group-wrapper .slick-slider .slick-list .slick-slide:last-child .tile-wrap {
  margin-right: 0;
}
.ProgressedTile {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px;
}
.ProgressedTile svg {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
.ProgressedTile .text {
  text-align: left;
  color: black;
}
.ProgressedTile .text h2, .ProgressedTile .text h3 {
  font-family: "Source Sans Pro", sans-serif;
}
.ProgressedTile .text h2 {
  font-weight: 500;
  font-size: 22px;
  padding-bottom: 5px;
}
.ProgressedTile .text h3 {
  font-weight: 100;
  font-size: 18px;
}

@media (max-width: 723px) {
  .ProgressedTile {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .ProgressedTile .text {
    display: none;
  }
}
.ProgressRing {
  z-index: 3;
}
.ProgressRing circle {
  stroke: white;
  transition: stroke-dashoffset 0.35s;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.ProgressRing circle.progress {
  stroke: #ffd61e;
}
.ProgressRing text {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  font-size: 26px;
}
@media (min-width: 723px) {
  .Lessons {
    text-align: center;
  }
  .Lessons h1 {
    margin: 40px 0;
  }
}
.QuestionItem {
  text-align: center;
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  padding: 21px 0;
  border-top: 1px solid #690986;
}
.QuestionItem.demo {
  background-image: url(/static/media/demo_answer_bg.ca83b9b2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  padding: 21px 15px 30px;
  border-radius: 20px;
}
.QuestionItem.demo .answer-wrap {
  background-color: white;
}
.QuestionItem.demo.revealed {
  background-image: none;
}
.QuestionItem.demo.revealed .content {
  color: #690986;
}
.QuestionItem.demo.revealed .answer-wrap {
  color: #ffffff;
  background-image: url(/static/media/demo_answer_bg.ca83b9b2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.QuestionItem.demo h2 {
  color: #f5af00;
  font-style: italic;
  font-size: 30px;
}
@media (min-width: 723px) {
  .QuestionItem.demo h2 {
    font-size: 50px;
  }
}
.QuestionItem.demo .content {
  color: #ffffff;
  font-size: 16px;
}
@media (min-width: 723px) {
  .QuestionItem.demo .content {
    font-size: 20px;
  }
}
.QuestionItem .btn.reveal-answer {
  padding: 4px 30px 4px 15px;
  text-align: left;
  color: #690986;
  line-height: 0.9;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  position: relative;
}
.QuestionItem .btn.reveal-answer span {
  width: 40px;
  z-index: 9;
}
.QuestionItem .btn.reveal-answer svg {
  fill: #690986;
  position: absolute;
  width: 52px;
  height: 52px;
  right: -8px;
  bottom: -11px;
  z-index: 10;
}
.QuestionItem:first-child {
  border: none;
}
.QuestionItem h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  color: #690986;
  font-size: 18px;
}
.QuestionItem .content {
  margin-bottom: 23px;
}
.QuestionItem .content img {
  margin-top: 10px;
  max-width: 100%;
}
.QuestionItem .answer-wrap {
  border: 1px solid #690986;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.QuestionItem .answer-wrap .answer {
  padding: 30px 20px;
  text-align: center;
}
.QuestionItem .answer-wrap .answer img {
  margin-top: 10px;
  max-width: 100%;
}
.QuestionItem .answer-wrap .overlay {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 3;
}
.QuestionItem .answer-wrap .overlay button {
  z-index: 2;
}
.QuestionItem .explanation-wrap {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 7px;
  margin-bottom: 3px;
  position: relative;
}
.QuestionItem .explanation-wrap iframe {
  width: 100%;
  height: auto;
}
@media (min-width: 723px) {
  .QuestionItem .explanation-wrap iframe {
    height: 318px;
  }
}
.QuestionItem .buttons-container {
  display: -webkit-flex;
  display: flex;
}
.QuestionItem .buttons-container .btn {
  padding: 6px 12px;
  width: 50%;
  -webkit-justify-content: center;
          justify-content: center;
  line-height: 1.3;
}
.QuestionItem .buttons-container .btn:first-child {
  margin-right: 5px;
}
.QuestionItem .response {
  display: -webkit-flex;
  display: flex;
}
.QuestionItem .response .btn {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding: 13px;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 5px;
}
.QuestionItem .response .ui.checkbox {
  height: 40px;
  width: 40px;
}
.QuestionItem .response .ui.checkbox.checked.fitted input:checked:focus ~ label:before,
.QuestionItem .response .ui.checkbox.checked.fitted input:checked:focus ~ label:after {
  color: #690986;
  border-color: #690986;
}
.QuestionItem .response .ui.checkbox label:before, .QuestionItem .response .ui.checkbox label:after {
  height: 40px;
  width: 40px;
  font-size: 35px;
  color: #690986;
  border-color: #690986;
}
.QuestionItem .response .ui.checkbox label:after {
  top: 10px;
}
.Questions {
  margin-top: 50px;
}
.Questions .questions-wrap {
  padding: 0 5px;
}

@media (min-width: 723px) {
  .Questions .questions-wrap {
    padding: 0 25%;
  }
}
.AccountEdit .form-wrapper {
  margin-top: 50px;
  border: 0.5px solid #690986;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 0 20px 30px 20px;
}
.AccountEdit .form-wrapper .initials {
  background-color: #690986;
  height: 87px;
  width: 87px;
  margin: -43px auto 0 auto;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 38px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Source Sans Pro", sans-serif;
}
.AccountEdit .form-wrapper .courses-info {
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin: 10px 0;
}
.AccountEdit .form-wrapper .courses-info ul {
  color: #f5af00;
  margin: 10px 0;
  padding: 0;
}
.AccountEdit .form-wrapper .courses-info ul li {
  list-style: none;
  margin-bottom: 2px;
}
.AccountEdit .form-wrapper button.manage-subscription {
  margin: 10px auto;
}
.AccountEdit .form-wrapper form {
  font-size: 12px;
  color: #690986;
}
.AccountEdit .form-wrapper form .input-wrapper label {
  font-weight: 800;
  margin-bottom: 8px;
}
.AccountEdit .form-wrapper form .input-wrapper input {
  border: 1px solid #690986;
  border-radius: 2px;
  height: 45px;
  margin-bottom: 15px;
}
.AccountEdit .form-wrapper form .btn {
  width: 100%;
}
.ui.btn.manage-subscription {
  text-transform: none;
}
.TrailBanner {
  background-color: #690986;
  padding: 10px 14px;
  margin: 0 -14px;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 10px;
}
.TrailBanner .content-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  font-size: 12px;
}
.TrailBanner .btn {
  text-transform: capitalize;
  border: 0.5px solid #FFFFFF;
  border-radius: 5px;
  padding: 5px 15px;
  font-weight: 900;
}

.home .TrailBanner {
  margin-bottom: -25px;
}
.Header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.Header .logo {
  height: 50px;
}
.Header .actions svg {
  height: 24px;
  width: 24px;
  margin-left: 9px;
}
.Header .actions svg.user {
  color: #690986;
}
.Header .actions svg.logout {
  color: #f5af00;
}

@media (min-width: 723px) {
  .Header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
  }
}
.PersonalAccountContainer {
  min-height: 100vh;
  padding: 20px 0px;
  background-image: url(/static/media/symbol_background.7ca4c195.png);
  background-size: contain;
}
body {
  font-family: "Source Sans Pro", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}

.checkbox-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.checkbox-wrapper.error label:before {
  border-color: red !important;
}
.checkbox-wrapper label, .checkbox-wrapper .checkbox {
  display: inline-block;
  margin: 0;
}

.input-wrapper .info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.validation-error {
  color: #cd4e61;
  text-align: right;
}
.validation-error::first-letter {
  text-transform: uppercase;
}

.screen-header {
  color: #690986;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 40px;
  font-style: italic;
}

@media (min-width: 723px) {
  .tile-wrapper {
    padding-left: 8%;
    padding-right: 8%;
  }
  .tile-wrapper h1 {
    margin-bottom: 30px;
  }
}
.Tile {
  width: 100%;
  height: 143px;
  opacity: 0.95;
  border-radius: 14px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}
.Tile .gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.85;
  z-index: 1;
}
.Tile .text h2, .Tile .text h3 {
  position: relative;
  display: block;
  z-index: 2;
  margin: 0;
}

.btn, .ui.btn {
  border: none;
  border-radius: 4px;
  padding: 13px;
  display: -webkit-flex;
  display: flex;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 12px;
  -webkit-align-items: center;
          align-items: center;
}
.btn svg, .ui.btn svg {
  margin-left: 5px;
  font-size: 15px;
}
.btn.btn-primary-gradient, .ui.btn.btn-primary-gradient {
  color: #fff;
  background: #690986; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #690986 0%, #690986 50%, #41094a 51%, #41094a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#6d2896", endColorstr="#56125b",GradientType=0 ); /* IE6-9 */
}
.btn.btn-primary, .ui.btn.btn-primary {
  color: #fff;
  background-color: #690986;
}
.btn.btn-primary-dark, .ui.btn.btn-primary-dark {
  color: #fff;
  background-color: #41094a;
}
.btn.btn-secondary-dark, .ui.btn.btn-secondary-dark {
  color: #fff;
  background-color: #f5af00;
}
.btn.btn-secondary, .ui.btn.btn-secondary {
  color: #fff;
  background-color: #ffd61e;
}

.form h3 {
  font-size: 18px;
  margin-bottom: 0;
  font-family: inherit;
  font-weight: 500;
}
.form .input-wrapper {
  margin-top: 5px;
}
.form .input-wrapper label {
  font-size: 12px;
  font-weight: 500;
}
.form .input-wrapper input {
  width: 100%;
  border: 1px solid #690986;
  border-radius: 2px;
  background-color: #FFFFFF;
  height: 45px;
  padding-left: 10px;
}
.form .input-wrapper input:disabled {
  background-color: #D8D8D8;
}
.form .input-wrapper .ui.input.focus > input, .form .input-wrapper .ui.input > input:focus {
  border-color: black;
  border-width: 2px;
}
.form .checkbox-wrapper {
  margin-top: 20px;
}
.form .checkbox-wrapper label {
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
}
.form .checkbox-wrapper .ui.checkbox {
  height: 25px;
  width: 25px;
}
.form .checkbox-wrapper .ui.checkbox label:focus::before,
.form .checkbox-wrapper .ui.checkbox label:active::before,
.form .checkbox-wrapper .ui.checkbox label:hover::before,
.form .checkbox-wrapper .ui.checkbox label:before,
.form .checkbox-wrapper .ui.checkbox input:focus ~ label:before,
.form .checkbox-wrapper .ui.checkbox input:checked ~ label:before {
  border-color: #690986;
  border-radius: 5px;
}
.form .checkbox-wrapper .ui.checkbox label:focus:after,
.form .checkbox-wrapper .ui.checkbox label:active:after,
.form .checkbox-wrapper .ui.checkbox label:hover:after,
.form .checkbox-wrapper .ui.checkbox label:after,
.form .checkbox-wrapper .ui.checkbox input:focus ~ label:after,
.form .checkbox-wrapper .ui.checkbox input:checked ~ label:after {
  color: #690986;
}
.form .checkbox-wrapper .ui.checkbox label:before, .form .checkbox-wrapper .ui.checkbox label:after {
  height: 25px;
  width: 25px;
  font-size: 22px;
}
.form .checkbox-wrapper .ui.checkbox label:after {
  top: -2px;
}

#MathJax_Message {
  display: none !important;
}

.calculator-image {
  z-index: 3;
  position: absolute;
  width: 19px;
  top: 10px;
  right: 10px;
}
.calculator-image.disallow {
  width: 22px;
}

.disabled-link {
  cursor: default;
  pointer-events: none;
}

.disabled .gradient {
  background: linear-gradient(270deg, #8e8e8e 0%, #565656 100%) !important;
}
